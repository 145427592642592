// LinkModal.js
import React, { useState, useEffect } from 'react';
import Select from 'react-select'; // Make sure to install react-select using npm or yarn
import apiClient from './apiClient'; // Import apiClient directly

let baseUrl = "/api";//"http://localhost:5001";//

const LinkModal = ({ link, entities, setLinks, setShowLinkModal, fetchLinks }) => {
    const [title, setTitle] = useState(link ? link.title : '');
    const [selectedEntities, setSelectedEntities] = useState(link ? link.entities : []);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [confirmDelete, setConfirmDelete] = useState('');

    const entityOptions = entities.map(entity => ({
        value: entity.id,
        label: `${entity.name} (${entity.platform})`,
        entity
    }));

    useEffect(() => {
        if (!title && selectedEntities.length === 1) {
            setTitle(selectedEntities[0].entity.name);
        }
    }, [selectedEntities, title]);

    const saveLink = async () => {
        try {
            const response = link
                ? await apiClient.put(`${baseUrl}/links/${link.id}`, { title, entities: selectedEntities })
                : await apiClient.post(`${baseUrl}/links`, { title, entities: selectedEntities });
            setLinks(prevLinks => [...prevLinks.filter(l => l.id !== response.data.id), response.data]);
        } catch (error) {
            console.error("There was an error saving the link!", error);
        }
        setShowLinkModal(false);
        fetchLinks();
    };

    const deleteLink = async () => {
        if (confirmDelete === 'DELETE' && link) {
            try {
                await apiClient.delete(`${baseUrl}/links/${link.id}`);
                setLinks(prevLinks => prevLinks.filter(l => l.id !== link.id));
            } catch (error) {
                console.error("There was an error deleting the link!", error);
            }
            setShowLinkModal(false);
            setShowDeleteConfirm(false);  // Hide the confirmation modal after deleting
            setConfirmDelete('');  // Clear the confirm delete input
        }
    };

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-60">
            {showDeleteConfirm && (
                <div className="fixed z-20 inset-0 overflow-y-auto bg-black bg-opacity-60">
                    <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                        <div className="inline-block align-bottom bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
                            <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                                <div className="sm:flex sm:items-start">
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                                        <h3 className="text-lg leading-6 font-medium text-white" id="modal-title">
                                            Confirm Delete
                                        </h3>
                                        <div className="mt-2">
                                            <p className="text-sm text-gray-400">
                                                Are you sure you want to delete this link? This action cannot be undone.
                                                Type "DELETE" to confirm.
                                            </p>
                                            <input
                                                type="text"
                                                onChange={(e) => setConfirmDelete(e.target.value)}
                                                className="bg-gray-800 text-white p-2 my-4 rounded border w-full"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                                <button
                                    onClick={deleteLink}
                                    type="button"
                                    className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm 
                            ${confirmDelete === 'DELETE'
                                            ? 'bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                                            : 'bg-gray-600 text-gray-400 cursor-not-allowed hover:bg-gray-600'}`}
                                    disabled={confirmDelete !== 'DELETE'}
                                >
                                    Confirm Delete
                                </button>
                                <button
                                    onClick={() => setShowDeleteConfirm(false)}
                                    type="button"
                                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>


                </div>
            )}
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
                <div className="inline-block align-middle bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                    <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="Title"
                            className="bg-gray-800 text-white p-2 my-2 rounded border w-full"
                        />
                        <Select
                            isMulti
                            options={entityOptions}
                            value={selectedEntities}
                            noOptionsMessage={() => "No Options - Load All Customers First"}
                            onChange={setSelectedEntities}
                            className="text-black my-2"
                            menuPortalTarget={document.body}
                            styles={{
                                menuPortal: base => ({
                                    ...base,
                                    zIndex: 9999
                                })
                            }}
                        />
                    </div>

                    <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                        <button
                            onClick={saveLink}
                            type="button"
                            disabled={!title.trim() || selectedEntities.length === 0} // added this line
                            className={`w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium 
        ${(!title.trim() || selectedEntities.length === 0)
                                    ? 'bg-gray-500 cursor-not-allowed hover:bg-gray-500'
                                    : 'bg-green-500 hover:bg-green-700 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500'} 
        sm:ml-3 sm:w-auto sm:text-sm`}
                        >
                            Save
                        </button>

                        {link && (
                            <button onClick={() => setShowDeleteConfirm(true)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-500 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm">
                                Delete
                            </button>
                        )}
                        <button onClick={() => setShowLinkModal(false)} type="button" className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LinkModal;
