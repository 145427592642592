import React, { useRef, useEffect, useState } from 'react';
import { GoogleMap, OverlayView, useJsApiLoader } from '@react-google-maps/api';

const mapContainerStyle = {
    width: "800px",
    height: "500px",
};

const center = {
    lat: -34.397,
    lng: 150.644,
};

function MapComponent({ image, overlaySettings, setOverlaySettings, onMapClick }) {
    const mapRef = useRef(null);
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: "AIzaSyDhBL-0BEewpUnzAfH3SukbwoEtMgtR-aE"
    });
    const currentCenter = {
        lat: (overlaySettings.bounds.north + overlaySettings.bounds.south) / 2,
        lng: (overlaySettings.bounds.east + overlaySettings.bounds.west) / 2,
    };

    useEffect(() => {
        if (mapRef.current) {
            const listener = mapRef.current.addListener("click", onMapClick);

            // Clean up the event listener when component unmounts
            return () => {
                window.google.maps.event.removeListener(listener);
            };
        }
    }, [mapRef.current, onMapClick]);

    const overlayRef = React.useRef(null);

    const initializeOverlay = () => {
        if (image && mapRef.current && isLoaded) {
            // Remove old overlay if exists
            if (overlayRef.current) {
                overlayRef.current.setMap(null);
            }
    
            const img = new Image();
            img.onload = () => {
                const imageAspectRatio = img.width / img.height;
                const currentBoundsWidth = overlaySettings.bounds.east - overlaySettings.bounds.west;
                const adjustedBoundsHeight = currentBoundsWidth / imageAspectRatio;
    
                const centerLat = (overlaySettings.bounds.north + overlaySettings.bounds.south) / 2;
                const adjustedNorth = centerLat + adjustedBoundsHeight / 2;
                const adjustedSouth = centerLat - adjustedBoundsHeight / 2;
    
                const imageBounds = new window.google.maps.LatLngBounds(
                    new window.google.maps.LatLng(adjustedSouth, overlaySettings.bounds.west),
                    new window.google.maps.LatLng(adjustedNorth, overlaySettings.bounds.east)
                );
    
                overlayRef.current = new window.google.maps.GroundOverlay(
                    URL.createObjectURL(image.file),
                    imageBounds
                );
    
                overlayRef.current.setMap(mapRef.current);
            };
            img.src = URL.createObjectURL(image.file);
        }
    };
    
    

    useEffect(() => {
        initializeOverlay();
    }, [image, overlaySettings]);

    if (!isLoaded) return null;

    return (
        <GoogleMap
            mapContainerStyle={mapContainerStyle}
            center={currentCenter}
            zoom={17}
            onLoad={map => {
                mapRef.current = map;
            }}
            mapTypeId={"satellite"} // Set map to satellite view
            tilt={0} // Disable tilt view

        >
        </GoogleMap>
    );
}

export default MapComponent;
