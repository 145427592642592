import React from "react";

function BuildingSelector({ buildings, onChange, currentBuilding }) {
    return (
        <div className="my-2" >
            <select value={currentBuilding} onChange={e => onChange(e.target.value)}>
                {buildings.map(building => (
                    <option key={building} value={building}>
                        {building}
                    </option>
                ))}
            </select>
        </div>
    );
}

export default BuildingSelector;
