// App.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from './logo.png';

const App = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/pmo');
    } else {
      // Initialize Google sign-in only if the user is not logged in
      window.google?.accounts.id.initialize({
        client_id: '82704432751-jiig5nrub857b3u1076s5m4d3us90oai.apps.googleusercontent.com',
        callback: handleCredentialResponse
      });

      window.google?.accounts.id.renderButton(
        document.getElementById('google-signin-button'),
        { theme: 'outline', size: 'large' }
      );
    }
  }, [navigate]); // Add navigate to the dependency array

  const handleCredentialResponse = (response) => {
    localStorage.setItem('token', response.credential);
    navigate('/pmo');
  };

  // If the user is logged in, they will never see the following JSX because they'll be redirected.
  return (
    <div className="flex bg-gray-900 flex-col items-center justify-center min-h-screen p-4">
      <img src={logo} alt="Company Logo" className="mb-8 h-24 w-auto" />
      <div className="inline-flex w-full justify-center">
        <div id="google-signin-button" className="w-auto inline-block"></div>
      </div>
    </div>
  );
};

export default App;
