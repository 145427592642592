import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import App from './App';
import Pmo from './Pmo';
import TearDown from './TearDown';
import MappApp from './MappApp/MappApp';

const PrivateRoute = ({ children }) => {
  const token = localStorage.getItem('token');
  return token ? children : <Navigate to="/" replace />;
};

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <Router>
      <Routes>
        <Route path="/*" element={<App />} />
        <Route 
          path="/pmo" 
          element={
            <PrivateRoute>
              <Pmo />
            </PrivateRoute>
          } 
        />
        <Route 
          path="/teardown" 
          element={
            <TearDown />
          } 
        />
        <Route 
          path="/mappapp" 
          element={
            <MappApp />
          } 
        />
      </Routes>
    </Router>
  </React.StrictMode>
);
