import React, { useState, useRef } from "react";

function ImageUploader({ onUpload }) {
    // State to keep track of uploaded file names
    const [uploadedFileNames, setUploadedFileNames] = useState("Upload Images");

    // Reference to the hidden file input element
    const fileInputRef = useRef(null);

    // Function to trigger the file input when the button is clicked
    const triggerFileInput = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files).map((file) => ({
            building: file.name.split("_")[0],
            floor: parseInt(file.name.split("_")[1], 10), // Parsing floor level as an integer
            file,
        })).sort((a, b) => a.floor - b.floor); // Sorting by floor level
        
        // Update the file names for display, sorted by floor
        const fileNames = files.map(file => file.file.name).join(', ');
        setUploadedFileNames(fileNames.length > 0 ? fileNames : "Upload Images");
    
        onUpload(files);
    };
    

    return (
        <div>
            {/* Custom upload button with dynamic text */}
            <button
                onClick={triggerFileInput}
                className="px-4 py-2 border border-gray-300 bg-white text-black rounded shadow"
            >
                {uploadedFileNames}
            </button>
            
            {/* Hidden file input for actual file selection */}
            <input
                type="file"
                multiple
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
        </div>
    );
}

export default ImageUploader;
