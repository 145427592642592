import React, { useState, useEffect, useRef } from "react";
import ImageUploader from "./ImageUploader";
import BuildingSelector from "./BuildingSelector";
import MapComponent from "./MapComponent";
import 'tailwindcss/tailwind.css';
import Papa from "papaparse";

function MappApp() {
    const [uploadedImages, setUploadedImages] = useState([]);
    const [currentBuilding, setCurrentBuilding] = useState("");
    const [currentFloorIndex, setCurrentFloorIndex] = useState(0);
    const [imageFilePath, setImageFilePath] = useState(localStorage.getItem('imageFilePath') || '');
    const [overlaySettings, setOverlaySettings] = useState({
        bounds: {
            north: -34.397,
            south: -34.400,
            east: 150.647,
            west: 150.644,
        },
        scale: 1,
    });
    const [username, setUsername] = useState(localStorage.getItem('username') || '');
    const [password, setPassword] = useState(localStorage.getItem('password') || '');
    const [sureviewDomain, setSureviewDomain] = useState(localStorage.getItem('SureViewDomain') || "us.sureviewops.com");
    const [csvData, setCsvData] = useState(
        JSON.parse(localStorage.getItem('csvData')) || []
    );
    const [uploadedFileName, setUploadedFileName] = useState(
        localStorage.getItem('uploadedFileName') || 'Upload Groups CSV'
    );

    // Effect to run once when the component mounts
    useEffect(() => {
        // Retrieve the CSV data from localStorage
        const storedCsvData = localStorage.getItem('csvData');
        if (storedCsvData) {
            setCsvData(JSON.parse(storedCsvData));
        }
    }, []);

    const handleCSVUpload = async (e) => {
        const file = e.target.files[0];
        if (file) {
            // Update the filename in the state and localStorage
            setUploadedFileName(file.name);
            localStorage.setItem('uploadedFileName', file.name);

            // Parse the CSV file
            Papa.parse(file, {
                header: true,
                complete: (results) => {
                    // Update the CSV data state variable
                    setCsvData(results.data);
                    // Save CSV data to localStorage
                    localStorage.setItem('csvData', JSON.stringify(results.data));
                },
                error: (error) => {
                    // Handle parsing errors
                    console.error("Error parsing CSV file:", error);
                }
            });
        }
    };

    // Handlers for username and password input changes
    const handleUsernameChange = (e) => {
        const newUsername = e.target.value;
        setUsername(newUsername);
        localStorage.setItem('username', newUsername);
    };
    const handlePasswordChange = (e) => {
        const newPassword = e.target.value;
        setPassword(newPassword);
        localStorage.setItem('password', newPassword);
    };
    const handleImagePathChange = (e) => {
        const path = e.target.value;
        setImageFilePath(path);
        localStorage.setItem('imageFilePath', path);
    };
    const handleSureViewDomainChange = (e) => {
        const path = e.target.value;
        setSureviewDomain(path);
        localStorage.setItem('SureViewDomain', path);
    };


    const moveImage = (dx, dy) => {
        setOverlaySettings(prev => ({
            ...prev,
            bounds: {
                north: prev.bounds.north + dy,
                south: prev.bounds.south + dy,
                east: prev.bounds.east + dx,
                west: prev.bounds.west + dx
            }
        }));
    };

    const moveFloorUp = (index) => {
        if (index <= 0) return;
        const newFloors = [...currentBuildingImages];
        [newFloors[index], newFloors[index - 1]] = [newFloors[index - 1], newFloors[index]];
        setUploadedImages(newFloors);
        // If the current selected floor is the one being moved, update the index
        if (currentFloorIndex === index) {
            setCurrentFloorIndex(index - 1);
        } else if (currentFloorIndex === index - 1) {
            setCurrentFloorIndex(index);
        }
    };

    const moveFloorDown = (index) => {
        if (index >= currentBuildingImages.length - 1) return;
        const newFloors = [...currentBuildingImages];
        [newFloors[index], newFloors[index + 1]] = [newFloors[index + 1], newFloors[index]];
        setUploadedImages(newFloors);
        // If the current selected floor is the one being moved, update the index
        if (currentFloorIndex === index) {
            setCurrentFloorIndex(index + 1);
        } else if (currentFloorIndex === index + 1) {
            setCurrentFloorIndex(index);
        }
    };


    const handleMapClick = (e) => {
        const lat = e.latLng.lat();
        const lng = e.latLng.lng();
        const halfLatDiff = (overlaySettings.bounds.north - overlaySettings.bounds.south) / 2;
        const halfLngDiff = (overlaySettings.bounds.east - overlaySettings.bounds.west) / 2;

        setOverlaySettings({
            bounds: {
                north: lat + halfLatDiff,
                south: lat - halfLatDiff,
                east: lng + halfLngDiff,
                west: lng - halfLngDiff
            }
        });
    };

    const handleImageUpload = (files) => {
        const updatedImages = [...uploadedImages, ...files];
        setUploadedImages(updatedImages);

        const newBuildings = [...new Set(files.map(img => img.building))];
        if (newBuildings.length > 0) {
            // Automatically select the first building of the new uploads.
            changeBuilding(newBuildings[0], updatedImages);

        }
    };

    const buildings = [...new Set(uploadedImages.map(img => img.building))];

    const getBuildingLocation = async (buildingTitle, imagesList = uploadedImages) => {

        const building = csvData.find(b => b.Title === buildingTitle);
        if (building) {
            const response = await fetch(`https://nominatim.openstreetmap.org/search?format=json&q=${building.Address}`);
            const [location] = await response.json();

            if (location) {
                const img = new Image();
                img.onload = () => {
                    const imageAspectRatio = img.width / img.height;
                    const currentBoundsWidth = overlaySettings.bounds.east - overlaySettings.bounds.west; // Getting width from initial overlaySettings
                    const adjustedBoundsHeight = currentBoundsWidth / imageAspectRatio;

                    setOverlaySettings({
                        bounds: {
                            north: parseFloat(location.lat) + adjustedBoundsHeight / 2,
                            south: parseFloat(location.lat) - adjustedBoundsHeight / 2,
                            east: parseFloat(location.lon) + currentBoundsWidth / 2,
                            west: parseFloat(location.lon) - currentBoundsWidth / 2,
                        }
                    });
                };
                const buildingImage = imagesList.find(img => img.building === buildingTitle);
                if (buildingImage) {
                    img.src = URL.createObjectURL(buildingImage.file);
                } else {
                    console.warn(`No image found for building: ${buildingTitle}`);
                }
            }
        }
    };


    const changeBuilding = (building, updatedList = uploadedImages) => {
        setCurrentBuilding(building);
        getBuildingLocation(building, updatedList);
        setCurrentFloorIndex(0);
    };

    const changeFloor = (increment) => {
        setCurrentFloorIndex(prevIndex => {
            let floorCount = uploadedImages.filter(img => img.building === currentBuilding).length;
            return (prevIndex + increment + floorCount) % floorCount;
        });
    };

    const zoomImage = (factor) => {
        const widthDiff = (overlaySettings.bounds.east - overlaySettings.bounds.west) * (factor - 1) / 2;
        const heightDiff = (overlaySettings.bounds.north - overlaySettings.bounds.south) * (factor - 1) / 2;

        setOverlaySettings(prev => ({
            ...prev,
            bounds: {
                north: prev.bounds.north + heightDiff,
                south: prev.bounds.south - heightDiff,
                east: prev.bounds.east + widthDiff,
                west: prev.bounds.west - widthDiff
            }
        }));
    };

    const clearCSVData = () => {
        setCsvData([]); // Clear the CSV data from state
        setUploadedFileName('Upload Group CSV'); // Reset the uploaded file name
        localStorage.removeItem('csvData'); // Clear the CSV data from localStorage
        localStorage.removeItem('uploadedFileName'); // Clear the file name from localStorage
        if (csvInputRef.current) {
            csvInputRef.current.value = ""; // Reset the file input
        }
    };

    function FloorList({ floors, currentFloorIndex, onFloorClick }) {
        return (
            <div className="mb-4">
                {floors.map((floor, index) => (
                    <div key={index} className="flex items-center">
                        <div
                            className={`flex-grow p-2 ${index === currentFloorIndex ? 'bg-blue-200' : ''} cursor-pointer`}
                            onClick={() => onFloorClick(index)}
                        >
                            {floor.file.name}
                        </div>
                        <button onClick={() => moveFloorUp(index)} className="p-1 mx-1 bg-gray-300 rounded shadow text-sm">↑</button>
                        <button onClick={() => moveFloorDown(index)} className="p-1 mx-1 bg-gray-300 rounded shadow text-sm">↓</button>
                    </div>
                ))}
            </div>
        );
    }


    const handlePlot = async () => {
        // Constants
        const groupId = csvData.find(b => b.Title === currentBuilding).GroupID;
        const EndpointRoot = `https://${sureviewDomain}/api/`;

        // Constants for headers
        const acceptHeader = "accept: application/json";
        const contentTypeHeader = "Content-Type: application/x-www-form-urlencoded";

        const encodedUsername = encodeURIComponent(username);
        const encodedPassword = encodeURIComponent(password);

        // Build the command string without encoding
        let commands = `# Authenticate\n`;
        commands += `curl -c ./cookie.txt -X POST -H "${acceptHeader}" -H "${contentTypeHeader}" -d "Username=${encodedUsername}&Password=${encodedPassword}" ${EndpointRoot}login\n\n`;
        commands += `# Upload Images\n`;

        // Further commands appending here...

        for (let i = 0; i < currentBuildingImages.length; i++) {
            const image = currentBuildingImages[i];
            const minElevation = i * 10 + 1;
            const maxElevation = (i + 1) * 10;
            const title = image.file.name.replace(".png", "");

            commands += `curl -b ./cookie.txt -X PUT -F "groupId=${groupId}" -F "title=${title}" `;
            commands += `-F "sw=${overlaySettings.bounds.south} ${overlaySettings.bounds.west}" `;
            commands += `-F "se=${overlaySettings.bounds.south} ${overlaySettings.bounds.east}" `;
            commands += `-F "ne=${overlaySettings.bounds.north} ${overlaySettings.bounds.east}" `;
            commands += `-F "nw=${overlaySettings.bounds.north} ${overlaySettings.bounds.west}" `;
            commands += `-F "rotation=0" -F "minElevation=${minElevation}" -F "maxElevation=${maxElevation}" `;
            // Inside the handlePlot function, within the loop where you generate the commands
            commands += `-F "image=@${imageFilePath.replace(/ /g, "\\ ")}/${title}.png" `; // Assuming the name is the title with .png
            commands += `${EndpointRoot}MapLayers\n\n`;
        }

        // Copy commands to clipboard
        navigator.clipboard.writeText(commands).then(() => {
            console.log('Commands copied to clipboard');
        }).catch(err => {
            console.error('Could not copy commands to clipboard', err);
        });

        // Proceed with the rest of your function...
    };


    const currentBuildingImages = uploadedImages.filter(img => img.building === currentBuilding);
    const currentImage = currentBuildingImages[currentFloorIndex];
    const csvInputRef = useRef(null);

    // Effect hook to initialize state from localStorage
    useEffect(() => {
        const storedFileName = localStorage.getItem('uploadedFileName');
        if (storedFileName) {
            setUploadedFileName(storedFileName);
        }
    }, []);

    // Function to open the file input dialog
    const triggerCSVInput = () => {
        csvInputRef.current.click();
    };


    return (
        <div className="container mx-auto p-4 bg-gray-100">
            {/* Custom upload button with dynamic text */}
            <button
                onClick={triggerCSVInput}
                className="px-4 py-2 border border-gray-300 bg-white text-black rounded shadow" // Added padding, border, and shadow
            >
                {uploadedFileName}
            </button>

            {/* Hidden file input for actual file selection */}
            <input
                type="file"
                accept=".csv"
                onChange={handleCSVUpload}
                style={{ display: 'none' }}
                ref={csvInputRef}
            />
            <button
                onClick={clearCSVData}
                className="px-4 py-2 ml-2 border border-red-300 bg-white text-black rounded shadow"
            >
                Clear CSV
            </button>
            {csvData.length > 0 && (
                <ImageUploader onUpload={handleImageUpload} />

            )}
            {currentBuilding && csvData.length > 0 && (
                <div className="bg-white px-4 py-1 rounded shadow"> {/* Added padding, rounded corners, and shadow to the container */}
                    <BuildingSelector buildings={buildings} onChange={changeBuilding} currentBuilding={currentBuilding} />

                    {/* Control buttons */}
                    <div className="flex flex-row mb-4">
                        <button onClick={() => moveImage(0, 0.0001)} className="px-3 py-1 mx-2 my-1 bg-green-500 text-white rounded shadow">Move Up</button>
                        <button onClick={() => moveImage(0, -0.0001)} className="px-3 py-1 mx-2 my-1 bg-green-500 text-white rounded shadow">Move Down</button>
                        <button onClick={() => moveImage(-0.0001, 0)} className="px-3 py-1 mx-2 my-1 bg-green-500 text-white rounded shadow">Move Left</button>
                        <button onClick={() => moveImage(0.0001, 0)} className="px-3 py-1 mx-2 my-1 bg-green-500 text-white rounded shadow">Move Right</button>
                        <button onClick={() => zoomImage(1.1)} className="px-3 py-1 mx-2 my-1 bg-purple-500 text-white rounded shadow">Bigger</button>
                        <button onClick={() => zoomImage(0.9)} className="px-3 py-1 mx-2 my-1 bg-purple-500 text-white rounded shadow">Smaller</button>
                    </div>

                    <MapComponent
                        image={currentImage}
                        overlaySettings={overlaySettings}
                        setOverlaySettings={setOverlaySettings}
                        onMapClick={handleMapClick}
                    />
                    {currentBuildingImages.length > 1 && (
                        <>
                            <button className="px-3 py-1 mx-2 my-2 bg-blue-500 text-white rounded shadow" onClick={() => changeFloor(-1)}>Previous Floor</button>
                            <button className="px-3 py-1 mx-2 my-2 bg-blue-500 text-white rounded shadow" onClick={() => changeFloor(1)}>Next Floor</button>
                        </>
                    )}
                    <FloorList
                        floors={currentBuildingImages}
                        currentFloorIndex={currentFloorIndex}
                        onFloorClick={setCurrentFloorIndex}
                    />
                    <input
                        type="text"
                        value={username}
                        onChange={handleUsernameChange}
                        placeholder="Enter username"
                        className="px-3 py-2 border border-gray-300 rounded shadow w-full mb-3" // Full width and margin bottom

                    />
                    <br />

                    <input
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        placeholder="Enter password"
                        className="px-3 py-2 border border-gray-300 rounded shadow w-full mb-3"
                    />
                    <br />

                    <input
                        type="text"
                        value={imageFilePath}
                        onChange={handleImagePathChange}
                        placeholder="Enter image file path"
                        className="px-3 py-2 border border-gray-300 rounded shadow w-full mb-3"
                    />
                    <br />

                    <input
                        type="text"
                        value={sureviewDomain}
                        onChange={handleSureViewDomainChange} // Use an arrow function to update the state
                        placeholder="Enter SureView domain"
                        className="px-3 py-2 border border-gray-300 rounded shadow w-full mb-3"
                    />
                    <br />

                    <button onClick={handlePlot} className="px-4 py-2 mb-2 bg-red-500 text-white rounded shadow">Copy Commands</button>
                </div>
            )}
        </div>
    );
}

export default MappApp;
