import React, { useState } from 'react';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import 'tailwindcss/tailwind.css';
import './App.css';

const TearDown = () => {
    const [searchParams] = useSearchParams();
    const ticketId = searchParams.get('ticketId');
    const [showModal, setShowModal] = useState(false);

    const handleTearDown = async () => {
        setShowModal(false);
    
        try {
            const response = await axios.post(
                `https://svfusion1.eastus2.cloudapp.azure.com/api/fullyDeleteTicket`,
                { ticketId: ticketId },
                { headers: { 'Content-Type': 'application/json' } }
            );
            alert("The tear down process has been initiated.");
        } catch (error) {
            alert("An error occurred while processing your request.");
            console.error("Tear Down Error:", error);
        }
    };
    
    return (
        <div className="flex items-center justify-center h-screen">
            <div className="text-center">
                <h1 className="text-lg font-bold mb-2">Tear Down Ticket</h1>
                <p className="text-red-500 mb-4">WARNING: This action will archive the associated Slack Channel and Clockify Project for all users. Please ensure all time is tracked and that the issue is resolved before proceeding.</p>
                <button 
                    onClick={() => setShowModal(true)} 
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                >
                    Archive Ticket
                </button>

                {showModal && (
                    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full flex justify-center items-center">
                        <div className="bg-white p-5 rounded-lg shadow-xl">
                            <h2 className="font-bold text-lg mb-4">Confirm Archive</h2>
                            <p>Are you sure you want to archive this ticket's Slack Channel and Clockify Project?</p>
                            <div className="flex justify-around mt-4">
                                <button 
                                    onClick={handleTearDown} 
                                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"
                                >
                                    Confirm
                                </button>
                                <button 
                                    onClick={() => setShowModal(false)} 
                                    className="bg-gray-300 hover:bg-gray-500 text-black font-bold py-2 px-4 rounded"
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TearDown;
