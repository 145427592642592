import React, { useState } from 'react';
import apiClient from './apiClient'; // Import apiClient directly

let baseUrl = "/api";//"http://localhost:5001";//

const Modal = ({ selectedEntity, setShowModal, fetchEntities, modalType }) => {
  const [newName, setNewName] = useState(selectedEntity.name ?? "");
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState('');

  const updateEntityName = async () => {
    if (newName !== selectedEntity.name && newName.trim() !== "") {
      const url = `${baseUrl}/entities/${selectedEntity.platform.toLowerCase()}/${selectedEntity.id}`;
      try {
        await apiClient({ method: 'POST', url, data: { name: newName } });
        fetchEntities();
        setShowModal(false);
      } catch (error) {
        console.error("There was an error updating the entity!", error);
      }
    }
  };

  const deleteEntity = async () => {
    if (confirmDelete === 'DELETE') {
      const url = `${baseUrl}/entities/${selectedEntity.platform.toLowerCase()}/${selectedEntity.id}`;
      try {
        await apiClient.delete(url);
        setShowModal(false);
        fetchEntities();
      } catch (error) {
        console.error("There was an error deleting the entity!", error);
      }
    }
  };

  const saveEntity = async () => {
    if (newName.trim() !== "") {
      const url = `${baseUrl}/entities/${selectedEntity.platform.toLowerCase()}`;
      try {
        await apiClient.post(url, { name: newName });
        fetchEntities();
        setShowModal(false);
      } catch (error) {
        console.error("There was an error adding the entity!", error);
      }
    }
  };


  return (
    <div className="fixed z-10 inset-0 overflow-y-auto bg-black bg-opacity-60">
      <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
        <div className="inline-block align-middle bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3 className="text-lg leading-6 font-medium text-white" id="modal-title">
                {modalType === 'add' ? 'Add New Entity' : `${selectedEntity.name} (${selectedEntity.platform})`}
              </h3>

              <input
                type="text"
                value={newName}
                onChange={(e) => setNewName(e.target.value)}
                className="bg-gray-800 text-white p-2 my-4 rounded border w-full"
              />
              {newName.length > 0 && newName.length < 3 &&
                <div className="text-red-500">
                  Entity name must be at least 3 characters long.
                </div>}
            </div>
          </div>

          <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:items-center sm:justify-between">
            {modalType !== 'add' && (
              <button
                onClick={() => setShowDeleteConfirm(true)}
                type="button"
                className="inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
              >
                Delete
              </button>
            )}

            <div className="sm:flex sm:items-center sm:ml-auto">
              <button
                onClick={modalType === 'add' ? saveEntity : updateEntityName}
                type="button"
                disabled={newName.length < 3}  // Disable button when name is less than 3 characters
                className={`w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 text-base font-medium 
            ${newName.length >= 3 ? 'bg-gray-700 text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    : 'bg-gray-600 text-gray-400 cursor-not-allowed hover:bg-gray-600'} sm:w-auto sm:text-sm sm:mr-3`}
              >
                {modalType === 'add' ? 'Save' : 'Update'}
              </button>

              <button
                onClick={() => setShowModal(false)}
                type="button"
                className="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
              >
                Close
              </button>
            </div>
          </div>

        </div>
      </div>

      {showDeleteConfirm && (
        <div className="fixed z-20 inset-0 overflow-y-auto bg-black bg-opacity-60">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center">
            <div className="inline-block align-bottom bg-gray-900 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
              <div className="bg-gray-900 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium text-white" id="modal-title">
                      Confirm Delete
                    </h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-400">
                        Are you sure you want to delete this entity? This action cannot be undone.
                        Type "DELETE" to confirm.
                      </p>
                      <input
                        type="text"
                        onChange={(e) => setConfirmDelete(e.target.value)}
                        className="bg-gray-800 text-white p-2 my-4 rounded border w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  onClick={deleteEntity}
                  type="button"
                  className={`w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 text-base font-medium sm:ml-3 sm:w-auto sm:text-sm 
                                            ${confirmDelete === 'DELETE'
                      ? 'bg-red-600 text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500'
                      : 'bg-gray-600 text-gray-400 cursor-not-allowed hover:bg-gray-600'}`}
                  disabled={confirmDelete !== 'DELETE'}
                >
                  Confirm Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-gray-700 text-base font-medium text-white hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Modal;